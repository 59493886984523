import React from "react";

class ContactTitle extends React.Component {
    
    render() {
       return (<div className="gridParent"><svg id={this.props.id} data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease" className={this.props.className} width="1358" height="206" viewBox="0 0 1358 206" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 102.816C0 83.232 4.416 65.664 13.248 50.112C22.08 34.368 34.08 22.08 49.248 13.248C64.608 4.416 81.6 0 100.224 0C122.112 0 141.216 5.28 157.536 15.84C173.856 26.4 185.76 41.376 193.248 60.768H161.856C156.288 48.672 148.224 39.36 137.664 32.832C127.296 26.304 114.816 23.04 100.224 23.04C86.208 23.04 73.632 26.304 62.496 32.832C51.36 39.36 42.624 48.672 36.288 60.768C29.952 72.672 26.784 86.688 26.784 102.816C26.784 118.752 29.952 132.768 36.288 144.864C42.624 156.768 51.36 165.984 62.496 172.512C73.632 179.04 86.208 182.304 100.224 182.304C114.816 182.304 127.296 179.136 137.664 172.8C148.224 166.272 156.288 156.96 161.856 144.864H193.248C185.76 164.064 173.856 178.944 157.536 189.504C141.216 199.872 122.112 205.056 100.224 205.056C81.6 205.056 64.608 200.736 49.248 192.096C34.08 183.264 22.08 171.072 13.248 155.52C4.416 139.968 0 122.4 0 102.816Z"  fill="none" strokeWidth="5" stroke="white"/>
        <path d="M323.269 205.344C304.645 205.344 287.653 201.024 272.293 192.384C256.933 183.552 244.741 171.36 235.717 155.808C226.885 140.064 222.469 122.4 222.469 102.816C222.469 83.232 226.885 65.664 235.717 50.112C244.741 34.368 256.933 22.176 272.293 13.536C287.653 4.704 304.645 0.288002 323.269 0.288002C342.085 0.288002 359.173 4.704 374.533 13.536C389.893 22.176 401.989 34.272 410.821 49.824C419.653 65.376 424.069 83.04 424.069 102.816C424.069 122.592 419.653 140.256 410.821 155.808C401.989 171.36 389.893 183.552 374.533 192.384C359.173 201.024 342.085 205.344 323.269 205.344ZM323.269 182.592C337.285 182.592 349.861 179.328 360.997 172.8C372.325 166.272 381.157 156.96 387.493 144.864C394.021 132.768 397.285 118.752 397.285 102.816C397.285 86.688 394.021 72.672 387.493 60.768C381.157 48.672 372.421 39.36 361.285 32.832C350.149 26.304 337.477 23.04 323.269 23.04C309.061 23.04 296.389 26.304 285.253 32.832C274.117 39.36 265.285 48.672 258.757 60.768C252.421 72.672 249.253 86.688 249.253 102.816C249.253 118.752 252.421 132.768 258.757 144.864C265.285 156.96 274.117 166.272 285.253 172.8C296.581 179.328 309.253 182.592 323.269 182.592Z"  fill="none" strokeWidth="5" stroke="white"/>
        <path d="M616.491 203.328H590.283L484.875 43.488V203.328H458.667V2.30401H484.875L590.283 161.856V2.30401H616.491V203.328Z"  fill="none" strokeWidth="5" stroke="white"/>
        <path d="M784.719 2.592V23.904H729.999V203.328H703.791V23.904H648.783V2.592H784.719Z"  fill="none" strokeWidth="5" stroke="white"/>
        <path d="M935.636 158.688H848.084L831.956 203.328H804.308L876.884 3.744H907.124L979.412 203.328H951.764L935.636 158.688ZM928.148 137.376L891.86 36L855.572 137.376H928.148Z"  fill="none" strokeWidth="5" stroke="white"/>
        <path d="M1001.25 102.816C1001.25 83.232 1005.67 65.664 1014.5 50.112C1023.33 34.368 1035.33 22.08 1050.5 13.248C1065.86 4.416 1082.85 0 1101.47 0C1123.36 0 1142.47 5.28 1158.79 15.84C1175.11 26.4 1187.01 41.376 1194.5 60.768H1163.11C1157.54 48.672 1149.47 39.36 1138.91 32.832C1128.55 26.304 1116.07 23.04 1101.47 23.04C1087.46 23.04 1074.88 26.304 1063.75 32.832C1052.61 39.36 1043.87 48.672 1037.54 60.768C1031.2 72.672 1028.03 86.688 1028.03 102.816C1028.03 118.752 1031.2 132.768 1037.54 144.864C1043.87 156.768 1052.61 165.984 1063.75 172.512C1074.88 179.04 1087.46 182.304 1101.47 182.304C1116.07 182.304 1128.55 179.136 1138.91 172.8C1149.47 166.272 1157.54 156.96 1163.11 144.864H1194.5C1187.01 164.064 1175.11 178.944 1158.79 189.504C1142.47 199.872 1123.36 205.056 1101.47 205.056C1082.85 205.056 1065.86 200.736 1050.5 192.096C1035.33 183.264 1023.33 171.072 1014.5 155.52C1005.67 139.968 1001.25 122.4 1001.25 102.816Z"  fill="none" strokeWidth="5" stroke="white"/>
        <path d="M1357.06 2.592V23.904H1302.34V203.328H1276.13V23.904H1221.13V2.592H1357.06Z"  fill="none" strokeWidth="5" stroke="white"/>
        </svg></div>);
    }
}

export default ContactTitle;