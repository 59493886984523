import React from "react";

class WorkTitle extends React.Component {

    
    render() {
        return (<div className="gridParent"><svg id={this.props.id} data-sal="slide-left" data-sal-delay="300" data-sal-easing="ease" className={this.props.className} width="842" height="206" viewBox="0 0 842 206" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M267.84 2.304L209.664 203.04H180.288L133.632 41.472L85.248 203.04L56.16 203.328L0 2.304H27.936L71.712 172.512L120.096 2.304H149.472L195.552 171.936L239.616 2.304H267.84Z" fill="none" strokeWidth="5" stroke="white"/>
        <path d="M387.529 205.056C368.905 205.056 351.913 200.736 336.553 192.096C321.193 183.264 309.001 171.072 299.977 155.52C291.145 139.776 286.729 122.112 286.729 102.528C286.729 82.944 291.145 65.376 299.977 49.824C309.001 34.08 321.193 21.888 336.553 13.248C351.913 4.416 368.905 0 387.529 0C406.345 0 423.433 4.416 438.793 13.248C454.153 21.888 466.249 33.984 475.081 49.536C483.913 65.088 488.329 82.752 488.329 102.528C488.329 122.304 483.913 139.968 475.081 155.52C466.249 171.072 454.153 183.264 438.793 192.096C423.433 200.736 406.345 205.056 387.529 205.056ZM387.529 182.304C401.545 182.304 414.121 179.04 425.257 172.512C436.585 165.984 445.417 156.672 451.753 144.576C458.281 132.48 461.545 118.464 461.545 102.528C461.545 86.4 458.281 72.384 451.753 60.48C445.417 48.384 436.681 39.072 425.545 32.544C414.409 26.016 401.737 22.752 387.529 22.752C373.321 22.752 360.649 26.016 349.513 32.544C338.377 39.072 329.545 48.384 323.017 60.48C316.681 72.384 313.513 86.4 313.513 102.528C313.513 118.464 316.681 132.48 323.017 144.576C329.545 156.672 338.377 165.984 349.513 172.512C360.841 179.04 373.513 182.304 387.529 182.304Z" fill="none" strokeWidth="5" stroke="white"/>
        <path d="M628.623 203.04L580.815 120.96H549.135V203.04H522.927V2.304H587.727C602.895 2.304 615.663 4.896 626.031 10.08C636.591 15.264 644.463 22.272 649.647 31.104C654.831 39.936 657.423 50.016 657.423 61.344C657.423 75.168 653.391 87.36 645.327 97.92C637.455 108.48 625.551 115.488 609.615 118.944L660.015 203.04H628.623ZM549.135 99.936H587.727C601.935 99.936 612.591 96.48 619.695 89.568C626.799 82.464 630.351 73.056 630.351 61.344C630.351 49.44 626.799 40.224 619.695 33.696C612.783 27.168 602.127 23.904 587.727 23.904H549.135V99.936Z" fill="none" strokeWidth="5" stroke="white"/>
        <path d="M807.01 203.04L724.354 111.456V203.04H698.146V2.304H724.354V95.328L807.298 2.304H840.418L749.41 102.816L841.282 203.04H807.01Z" fill="none" strokeWidth="5" stroke="white"/>
        </svg>
        </div>);
    }
    
    
    
}

export default WorkTitle;